import React from "react";
import styled from "styled-components";
import { DetailNavLink, DetailNavLinkType } from "../contentArea/detailPages/DetailNavLink";
import BookmarkWithToggle from "./BookmarkWithToggle";
import { Contact, Exhibitor, Person } from "../backendServices/Types";
import { IconMeetingSchedule, IconCall, IconShare } from "./Icons";
import CrsPersons from "./CrsPersons";
import { Link, useRouteMatch } from "react-router-dom";
import { useLanguageState } from "../globalStates/LanguageState"
import { OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap"
// eslint-disable-next-line
import { defaultLogger as logger } from "../globalStates/AppState"

import branding from "../branding/branding";
import { sectionOrder, Sections, SectionType } from "../contentArea/useSearch";
import MeetingDetails from "../ui/MeetingDetails";
import { BasisPremiumConfig, BasisPremiumType, getBasisPremiumConfig, getDefaultBasisPremiumConfig, TileSize } from "../branding/BasisPremiumBranding";
import { exhibitorStartupPageRoute, mediaPartnerPageRoute, sponsorsPageRoute } from "../navigationArea/RoutePaths";
import { SimpleOrganization } from "../contentArea/ExhibitorsPageContent";
import { UserOrganizationVisitSource } from "../backendServices/BackendServices";

//used for custom Column element (CompanyCol)
const maxColumnWidth = 24

/* #region  Global styled components */
const CompanyGrid = styled.div`
    width: 100%;
    display: flex;
    font-family: ${branding.font1};
    margin: 0;
    /* padding: 0 20px; */
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 55px;
`
const CompanyRow = styled(Row)`
    width: 100%;
    margin: 0;
    padding: 0;
`
interface CompanyColProps {
    imageurl: string
    height: string
    basisPremiumConfig: BasisPremiumConfig
    isfiller?: boolean
    isNoneClickable?: boolean
    xs: number
}

const CompanyCol = styled.div<CompanyColProps>` 
    width: ${props => ((props.xs / maxColumnWidth) * 100) + "%"};
    border: ${props => props.basisPremiumConfig.tileBackgroundImageVisible ? "none" : "0.2px solid " + branding.mainInfoColor};
    height: ${props => props.height};
    background: ${props => props.imageurl !== "" ? "url(" + props.imageurl + ")" : "white"};
    background-size: ${props => props.basisPremiumConfig.tileSize === TileSize.SMALL ? "40%" : "cover"};
    background-position: ${props => props.basisPremiumConfig.tileSize === TileSize.SMALL ? "center" : branding.exhibitorsPageContent.largeTileBgPosition};
    background-repeat: no-repeat;
    padding:0;
    cursor: ${props => !props.isfiller && props.basisPremiumConfig.tileSize !== TileSize.SMALL ? "pointer" : !props.isfiller && props.basisPremiumConfig.tileSize === TileSize.SMALL && props.isNoneClickable ? "pointer" : "default"}; 
    float: left;
    position: relative;
    border: 5px solid #fff;
    border-radius: 10px;
    
    @media (max-width: 1400px) {
        height: ${props => props.basisPremiumConfig.tileSize === TileSize.SMALL ? "150px" : "300px"};
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: ${branding.exhibitorsPageContent.companiesTilesLayoutOverlay ?? "rgba(0,0,0, .5)"};
        box-shadow: inset 0px 0px 0px ${branding.exhibitorsPageContent.exhibitorTilesBorderWidth || 0} ${branding.exhibitorsPageContent.exhibitorTilesBorderColor || ''};
        border-radius: 5px;
    } 
    
    & .detailNavLink{
        height: 100%;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 2;
    }
`

const CompanyColFiller = styled.div<CompanyColProps>` 
    width: ${props => ((props.xs / maxColumnWidth) * 100) + "%"};
    border: ${props => props.basisPremiumConfig.tileBackgroundImageVisible ? "none" : "0.2px solid " + branding.mainInfoColor};
    height: ${props => props.height};
    background: ${props => props.imageurl !== "" ? "url(" + props.imageurl + ")" : "white"};
    background-size: ${props => props.basisPremiumConfig.tileSize === TileSize.SMALL ? "40%" : "cover"};
    background-position: ${props => props.basisPremiumConfig.tileSize === TileSize.SMALL ? "center" : branding.exhibitorsPageContent.largeTileBgPosition};
    background-repeat: no-repeat;
    padding:0;
    cursor: ${props => !props.isfiller && props.basisPremiumConfig.tileSize !== TileSize.SMALL ? "pointer" : !props.isfiller && props.basisPremiumConfig.tileSize === TileSize.SMALL && props.isNoneClickable ? "pointer" : "default"}; 
    float: left;
    position: relative;
    border: 5px solid #fff;
    border-radius: 10px;
    
    @media (max-width: 1400px) {
        height: ${props => props.basisPremiumConfig.tileSize === TileSize.SMALL ? "150px" : "300px"};
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        box-shadow: inset 0px 0px 0px #fff;
        border-radius: 15px;
    } 
    
    & .detailNavLink{
        height: 100%;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 2;
    }
`


const CompanyColSmall = styled(CompanyCol)`
    &::after {
        border-radius: 5px;
    }

    & > :first-child {
        display: none;
    }

    &:hover > :first-child {
        display: flex;
    }
    &:hover > :nth-child(2) {
        display: none;
    }
`

const CompanyColNone = styled.div<{ xs: number }>`
    width: ${props => ((props.xs / maxColumnWidth) * 100) + "%"};
    float: left;
    position: relative;
    padding: 0; 
    height: "200px";
    cursor: default;
`

const CompanyLogoDiv = styled.div`
    width: 100px;
    height: 100px;
    background: white;
    border: 1px solid ${branding.exhibitorsPageContent.companyTileLogoBorderColor ?? "#000"}; 
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;

    @media (max-width: 1400px) {
        width: 60px;
        height: 60px;  
        padding: 5px; 
    }

   & p{
    font-family: ${branding.font1};
    max-width: 40px;
    font-size: 10px;
    line-height: 0.6rem;
    color: rgb(0, 0, 0);
    text-align: center;
    display: inline-table;
    margin-bottom: 0;
    white-space: normal;
    word-break: break-word;
   }

`

const CompanyLogo = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`
const CompanyDescriptionRoot = styled(Row)`
    padding: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    z-index: 2;

    @media(max-width: 1400px){
        padding: 15px;
    }
`
interface CompanyDescriptionProps {
    color: string
}

const CompanyDescription = styled.div<CompanyDescriptionProps>`
    max-width: 350px;
    color:${props => props.color};
    margin-right: 40px;
    display: inline-block;
    max-height: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    font-size: 12px;
    font-weight: 400;

    @media (max-width: 1600px) { 
        font-size:12px;
    }

    @media (max-width: 1400px) { 
        font-size:12px;
        -webkit-line-clamp: 3;
    }
`

interface CompanyNameProps {
    color: string
}

const CompanyName = styled.h5<CompanyNameProps>`
    color:${props => props.color};

    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 18px;
    font-weight: 700;

    @media (max-width: 1400px) { 
        font-size:16px;
    }
`
/* #endregion */


/* #region  Tile buttons component */
const TileButtonsRoot = styled.div<{ width: string, tileSize: TileSize }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: ${props => props.width};

    @media (max-width: 2000px) { 
        width: ${props => props.tileSize === TileSize.SMALL ? "100%" : props.width};
    }
`

interface TileIconDivProps {
    display?: string
    strokeColor?: string
    visibility?: string
}
const TileIconDiv = styled.div<TileIconDivProps>`
    display: ${props => props.display};
    cursor: pointer;
    color: ${props => props.strokeColor};
    visibility: ${props => props.visibility};
    @media (max-width: 1200px) { 
        padding-left: 1rem;
    }
`

interface TileButtonsProps {
    basisPremiumConfig: BasisPremiumConfig
    organization: SimpleOrganization
    basisPremiumType: BasisPremiumType
    onAction?: (action: "EXPO" | "MEETING" | "INTEREST" | "RECOMMEND", organization: SimpleOrganization) => void
}

const TileButtons: React.FunctionComponent<TileButtonsProps> = (props) => {
    const strings = useLanguageState().getStrings();
    const strokeColor = props.basisPremiumConfig.tileBackgroundImageVisible ? "white" : branding.exhibitorsPageContent.companyTileBasicTextColor ?? "#000";
    const display = props.basisPremiumConfig.tileSize === TileSize.SMALL || props.basisPremiumConfig.tileSize === TileSize.QUARTER ? "none" : "block";
    const width = props.basisPremiumConfig.tileSize === TileSize.SMALL ? "100%" : "160px";
    const visibility = props.basisPremiumConfig.tileSize === TileSize.SMALL && !branding.exhibitorsPageContent.iconsVisibleOnSmallTiles ? "hidden" : "visible";

    function handleAction(action: "EXPO" | "MEETING" | "INTEREST" | "RECOMMEND", event: any) {
        if (props.onAction) {
            event.preventDefault()
            event.stopPropagation()
            props.onAction(action, props.organization)
        }
    }

    return (
        <TileButtonsRoot width={width} tileSize={props.basisPremiumConfig.tileSize}>

            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                        {strings.contactEntry.requestMeetingHint}
                    </Tooltip>}>
                <TileIconDiv display={display} onClick={(e) => {
                    handleAction('MEETING', e)
                }}>
                    {IconMeetingSchedule({ stroke: strokeColor, fill: strokeColor })}
                </TileIconDiv>
            </OverlayTrigger>

            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                        {strings.organizationDetailPageContent.registerInterest}
                    </Tooltip>}>
                <TileIconDiv visibility={visibility} onClick={(e) => {
                    handleAction('INTEREST', e)
                }}>
                    {IconCall({ stroke: strokeColor, fill: strokeColor })}
                </TileIconDiv>
            </OverlayTrigger>

            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                        {strings.contactEntry.shareHint}
                    </Tooltip>}>
                <TileIconDiv visibility={visibility} onClick={(e) => {
                    handleAction('RECOMMEND', e)
                }}>
                    {IconShare({ stroke: strokeColor, fill: strokeColor })}
                </TileIconDiv>
            </OverlayTrigger>

            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                        {strings.organizationDetailPageContent.bookmarkPerson}
                    </Tooltip>}>
                <TileIconDiv visibility={visibility} strokeColor={strokeColor}>
                    <BookmarkWithToggle newBookmarkItem={true} fontSize={"25px"} color={strokeColor} stroke={strokeColor} favIconBasic={true} type={"organization"} id={props.organization.id} />
                </TileIconDiv>
            </OverlayTrigger>

        </TileButtonsRoot>
    )
}
/* #endregion */


/* #region  Tile header */
const TileHeaderRow = styled(Row)`
    margin-bottom: 5rem;
    padding: 25px;
    //padding: 30px;
    position: relative;
    z-index: 2;

    @media (max-width: 1400px) { 
        padding: 15px;
    }
    
`
interface CompanyLogoColProps {
    type: BasisPremiumType
}
const CompanyLogoCol = styled(Col) <CompanyLogoColProps>`
    
`
interface TileButtonsColProps {
    type: BasisPremiumType
}
const TileButtonsCol = styled(Col) <TileButtonsColProps>`
   display: flex;
   justify-content: flex-end;
   align-items: flex-start;
`

interface TileHeaderProps {
    tileData: TileData
    onAction: (action: "EXPO" | "MEETING" | "INTEREST" | "RECOMMEND", organization: SimpleOrganization) => void
}

const TileHeader: React.FC<TileHeaderProps> = (props) => {
    return (
        <TileHeaderRow>
            <CompanyLogoCol xs={6}>
                <CompanyLogoDiv>
                    {props.tileData.pictureURL ? <CompanyLogo src={props.tileData.pictureURL} /> : <p>{props.tileData.name}</p>}
                </CompanyLogoDiv>
            </CompanyLogoCol>
            <TileButtonsCol xs={6}>
                <TileButtons
                    basisPremiumConfig={props.tileData.basisPremiumConfig}
                    organization={props.tileData}
                    basisPremiumType={props.tileData.basisPremiumType!}
                    onAction={props.onAction}
                />
            </TileButtonsCol>
        </TileHeaderRow>
    )
}
/* #endregion */


/* #region  Tile body */
const MeetingDetailsCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
    align-items: center;   

    @media (max-width: 1600px) {
        align-items: flex-end;
        flex-direction: column-reverse;
        margin-bottom: -10px;    
    }
`
const ConnectWithUsRow = styled(Row)`
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    color: #FFF;
    margin-bottom: 20px;
`

interface TileBodyProps {
    content: TileData
    strings: any
}

const TileBody: React.FC<TileBodyProps> = (props) => {
    const color = props.content.basisPremiumConfig.tileBackgroundImageVisible ? "white" : branding.exhibitorsPageContent.companyTileBasicTextColor;
    const hiddenContacts = props.content.basisPremiumConfig.tileSize !== TileSize.FULL
    const hiddenDetails = props.content.basisPremiumConfig.tileSize === TileSize.QUARTER || props.content.basisPremiumConfig.tileSize === TileSize.SMALL
    const size = props.content.basisPremiumConfig.tileSize === TileSize.FULL ? 4 : (props.content.basisPremiumConfig.tileSize === TileSize.THREE_QUARTERS || props.content.basisPremiumConfig.tileSize === TileSize.HALF) ? 6 : 12
    const persons = ((props.content.contacts as any) as Person[])?.filter(person => person.visible)

    return (
        <CompanyDescriptionRoot>
            <Col xs={size}>
                <CompanyName color={color}>{props.content.name}</CompanyName>
                {props.content.teaser && <CompanyDescription color={color}>
                    {props.content.teaser.split('\\n').map((item, i) => <span key={i}>{item}<br /></span>)}
                </CompanyDescription>}
            </Col>
            <Col xs={size} hidden={hiddenContacts}>
                {persons && persons.length > 0 &&
                    <>
                        <ConnectWithUsRow>{props.strings.organizationDetailPageContent.connectWithUs}</ConnectWithUsRow>
                        {/* TODO remove cast. Therefore Contact and Person Type should be merged */}
                        <CrsPersons key={props.content.id} layoutType={1} showModalPopup={false} persons={persons} personsToShow={5} />
                    </>
                }
            </Col>
            <MeetingDetailsCol xs={size} hidden={hiddenDetails} >
                <MeetingDetails content={props.content} color={color} />
            </MeetingDetailsCol>
        </CompanyDescriptionRoot >
    )
}
/* #endregion */


/* #region  Organization tile main content */
const HoverDiv = styled.div`
    background-color: ${branding.exhibitorsPageContent.companiesTilesLayoutHoverOverlay ?? "rgba(0, 0, 0, 0.2)"};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
`

const TileButtonsNoneDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    padding: 20px;

    @media (max-width: 2200px) { 
       padding: 10px;
       width: 100%;
    }
`
const CompanyNameDiv = styled.div`
    margin-top: 6rem;
    font-size: 16px;
    color: ${branding.exhibitorsPageContent.companyTileBasicTextColor ?? "#000"};
    width: 100%;

    p{
        max-width: 80%;
        color:${props => props.color};
        display: inline-block;
        max-height: 200px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
        word-break: break-all;

        @media(max-width: 1600px){
            max-width: 50%;
        } 
    }
`

const CompanyNameDivAlt = styled(CompanyNameDiv)`
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    & p {
        word-break: inherit;
        text-align: center;
    }
`

const SectionHeaderTitle = styled.div`
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    /* font-weight: bold; */
    margin: 48px 0 16px 25px;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor ?? "#000"};
`

interface FillerTemplateNameDivProps {
    display: string
    color: string
    height: string
}

const FillerTemplateNameDiv = styled.div<FillerTemplateNameDivProps>`
    display: ${props => props.display};
    color: ${props => props.color};
    height: 50%;
    width: 100%;
    position: relative;
    z-index: ${props => props.color === "#FFF" ? 2 : 0};
    //z-index: ${props => props.color === "#FFF" ? 2 : 20};

    h4 {
    font-size: 18px;
    font-weight: 700;
    }

    h6 {
    font-size: 16px;
    font-weight: 500;
    }
    
    p{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        width: 100%;
    }

    @media (max-width: 1400px) { 
        font-size: 12px;
    }
`

interface FillerTemplateLinkDivProps {
    display: string
    color?: string
}

const FillerTemplateLinkDiv = styled.div<FillerTemplateLinkDivProps>`
    display: ${props => props.display};
    font-size: 18px;
    color:${props => props.color ? props.color : "#FFF"};
    position: relative;
    z-index: ${props => props.color === "#FFF" ? 2 : 100};
    height: 50%;
    align-items: flex-end;
    & a{
        color: ${props => props.color ? props.color : "#FFF"};
        text-decoration: none;
    }

    @media(max-width: 1600px){
        font-size: 15px;
    }
`

interface FillerTemplateLinkParagraphProps {
    borderBottom: string
}

const FillerTemplateLinkParagraph = styled.p<FillerTemplateLinkParagraphProps>`
    border-bottom: ${props => props.borderBottom};
    font-size: 18px;

    @media (max-width: 1600px) { 
        font-size: 15px;
    }

    @media (max-width: 1600px) { 
        font-size: 12px;
    }
    
    @media (max-width: 1400px) { 
        font-size: 10px;
    }
`

interface FillerTileTemplateProps {
    content: TileData
    display: string
}

const FillerTileTemplate: React.FC<FillerTileTemplateProps> = (props) => {
    const color = props.content.basisPremiumConfig.tileSize === TileSize.QUARTER ? branding.exhibitorsPageContent.companyTileBasicTextColor ?? "#000" : "#FFF";
    const height = props.content.basisPremiumConfig.tileSize === TileSize.QUARTER ? "80%" : "85%";
    const borderBottom = props.content.basisPremiumConfig.tileSize === TileSize.QUARTER ? "1px solid " + branding.exhibitorsPageContent.companyTileBasicTextColor ?? "#000" : "1px solid white";

    return (
        <>
            <FillerTemplateNameDiv className="row p-4" display={props.display} color={color} height={height}>
                <div>
                    <h4>{props.content.name}</h4>
                    {props.content.teaser && <h6>{props.content.teaser}</h6>}
                </div>
            </FillerTemplateNameDiv>
            <FillerTemplateLinkDiv className="row p-4" display={props.content.basisPremiumConfig.tileSize === TileSize.SMALL ? "flex" : props.display} color={color}>
                {!props.content.fillerLink?.startsWith("/") &&
                    <a href={props.content.fillerLink} target="_blank" rel="noopener noreferrer">
                        <FillerTemplateLinkParagraph borderBottom={"1px solid " + color}>{props.content.fillerLinkText}</FillerTemplateLinkParagraph>
                    </a>}
                {props.content.fillerLink?.startsWith("/") &&
                    <Link to={props.content.fillerLink}>
                        <FillerTemplateLinkParagraph borderBottom={borderBottom}>{props.content.fillerLinkText}</FillerTemplateLinkParagraph>
                    </Link>
                }
            </FillerTemplateLinkDiv>
        </>
    )
}

interface FillerNoneDivProps {
    background: string
}

const FillerNoneDiv = styled.div<FillerNoneDivProps>`
    height: 200px;
    width: 100%;
    padding: 1rem 2rem;
    background: ${props => props.background};
    border: 1px solid ${branding.exhibitorsPageContent.companyTileBasicTextColor ?? "#000"};
    position: relative;
    z-index: 2;
    border: 5px solid #fff;
    box-shadow: inset 0px 0px 0px ${branding.exhibitorsPageContent.exhibitorTilesBorderWidth || 0} ${branding.exhibitorsPageContent.exhibitorTilesBorderColor || ''}; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;

    @media (max-width: 1400px) {
        height: 150px;
        /* padding: 2px 5px; */
    }

`

interface FillerNoneProps {
    tileData: TileData
}

const FillerNone: React.FC<FillerNoneProps> = (props) => {
    const color = props.tileData.fillerColor === "#FFF" ? branding.exhibitorsPageContent.companyTileBasicTextColor ?? "#000" : "#FFF";
    return (
        <FillerNoneDiv background={props.tileData.fillerColor!}>
            <FillerTemplateNameDiv className="row" height={"70%"} color={color} display={"flex"}>
                <p>{props.tileData.name}</p>
            </FillerTemplateNameDiv>
            <FillerTemplateLinkDiv className="row" display={"flex"} color={color}>
                {!props.tileData.fillerLink?.startsWith("/") &&
                    <a href={props.tileData.fillerLink} target="_blank" rel="noopener noreferrer">
                        <FillerTemplateLinkParagraph borderBottom={"1px solid " + color}>{props.tileData.fillerLinkText}</FillerTemplateLinkParagraph>
                    </a>}
                {props.tileData.fillerLink?.startsWith("/") &&
                    <Link to={props.tileData.fillerLink}>
                        <FillerTemplateLinkParagraph borderBottom={"1px solid " + color}>{props.tileData.fillerLinkText}</FillerTemplateLinkParagraph>
                    </Link>
                }
            </FillerTemplateLinkDiv>
        </FillerNoneDiv>
    )
}

interface TileData {
    id: string
    name: string
    teaser?: string
    pictureURL?: string
    backgroundImageURL?: string
    basisPremiumType?: BasisPremiumType
    basisPremiumConfig: BasisPremiumConfig
    contacts: Contact[]
    isFiller?: boolean
    fillerColor?: string
    fillerLinkText?: string
    fillerLink?: string
    initials?: string
    filler: TileData | null
}

interface OrganizationTileProps {
    tileData: TileData
    src: UserOrganizationVisitSource
    searchKrit?: string
    onAction: (action: "EXPO" | "MEETING" | "INTEREST" | "RECOMMEND", organization: SimpleOrganization) => void
    strings: any
}

const OrganizationTile: React.FC<OrganizationTileProps> = (props) => {
    const isNoneClickable = branding.exhibitorsPageContent.companyTileNoneClickable;
    const tileSize = props.tileData.basisPremiumConfig.tileSize

    let imageUrl = props.tileData.backgroundImageURL ? props.tileData.backgroundImageURL : "/branding/default_brand_card.jpg"
    if (!props.tileData.basisPremiumConfig.tileBackgroundImageVisible)
        imageUrl = ""

    if (tileSize === TileSize.SMALL) {
        if (props.tileData.isFiller) {
            return (
                <CompanyColNone xs={3}>
                    <FillerNone tileData={props.tileData} />
                    <FillerNone tileData={props.tileData.filler!} />
                </CompanyColNone>
            )
        } else {
            return (
                <CompanyColNone xs={3}>
                    <DetailNavLink id={props.tileData.id} type="organization" name={props.tileData.name} source={props.src} searchKrit={props.searchKrit} onClick={(e) => {
                        if (!isNoneClickable) {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }}>
                        <CompanyColSmall xs={24} height="200px"
                            imageurl={props.tileData.pictureURL!}
                            basisPremiumConfig={props.tileData.basisPremiumConfig}
                            isfiller={false}
                            isNoneClickable={isNoneClickable}
                        >
                            <HoverDiv>
                                <TileButtonsNoneDiv>
                                    <TileButtons
                                        basisPremiumConfig={props.tileData.basisPremiumConfig}
                                        basisPremiumType={props.tileData.basisPremiumType!}
                                        organization={props.tileData}
                                        onAction={props.onAction} />
                                    <CompanyNameDiv>
                                        <p>{props.tileData.name}</p>
                                    </CompanyNameDiv>
                                </TileButtonsNoneDiv>
                            </HoverDiv>
                            {!props.tileData.pictureURL && <CompanyNameDivAlt>
                                <p>{props.tileData.name}</p>
                            </CompanyNameDivAlt>}
                        </CompanyColSmall>
                    </DetailNavLink>
                    {props.tileData.filler && !props.tileData.filler.isFiller &&
                        <DetailNavLink id={props.tileData.filler.id} type="organization" name={props.tileData.filler.name} source={props.src} searchKrit={props.searchKrit} onClick={(e) => {
                            if (!isNoneClickable) {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}>
                            <CompanyColSmall xs={24} height="200px"
                                imageurl={props.tileData.filler.pictureURL!}
                                basisPremiumConfig={props.tileData.basisPremiumConfig}
                                isfiller={false}
                                isNoneClickable={isNoneClickable}
                            >
                                <HoverDiv>
                                    <TileButtonsNoneDiv>
                                        <TileButtons
                                            basisPremiumConfig={props.tileData.filler.basisPremiumConfig!}
                                            basisPremiumType={props.tileData.basisPremiumType!}
                                            organization={props.tileData.filler}
                                            onAction={props.onAction} />
                                        <CompanyNameDiv>
                                            <p>{props.tileData.filler.name}</p>
                                        </CompanyNameDiv>
                                    </TileButtonsNoneDiv>
                                </HoverDiv>
                                {!props.tileData.filler.pictureURL && <CompanyNameDivAlt>
                                    <p>{props.tileData.filler.name}</p>
                                </CompanyNameDivAlt>}
                            </CompanyColSmall>
                        </DetailNavLink>}
                    {props.tileData.filler && props.tileData.filler.isFiller &&
                        <CompanyColFiller xs={24} style={{ border: 0 }} height="200px" basisPremiumConfig={props.tileData.basisPremiumConfig} isfiller={true} imageurl="">
                            <FillerNone tileData={props.tileData.filler} />
                        </CompanyColFiller>}
                </CompanyColNone >
            )
        }
    } else if (tileSize === TileSize.QUARTER) {
        const display = props.tileData.backgroundImageURL ? "none" : "flex";

        if (props.tileData.isFiller) {
            return (
                <CompanyCol xs={6} className="pt-2 pb-2 pl-4 pr-4" imageurl={props.tileData.backgroundImageURL!} height="400px" basisPremiumConfig={props.tileData.basisPremiumConfig} isfiller={true}>
                    <FillerTileTemplate content={props.tileData} display={display} />
                </CompanyCol>
            )
        } else {
            return (
                <CompanyCol xs={6} imageurl={imageUrl} height="400px" basisPremiumConfig={props.tileData.basisPremiumConfig} isfiller={false}>
                    <DetailNavLink id={props.tileData.id} type="organization" name={props.tileData.name} source={props.src} searchKrit={props.searchKrit}>
                        <TileHeader
                            tileData={props.tileData}
                            onAction={props.onAction} />
                        <TileBody content={props.tileData} strings={props.strings} />
                    </DetailNavLink>
                </CompanyCol>
            )
        }
    } else if (tileSize === TileSize.HALF) {
        if (props.tileData.isFiller) {
            return (
                <CompanyCol xs={12} className="pt-2 pb-2 pl-4 pr-4" imageurl={"/branding/custom-filler-image.png"} height={"400px"} basisPremiumConfig={props.tileData.basisPremiumConfig} isfiller={true}>
                    <FillerTileTemplate content={props.tileData} display={"flex"} />
                </CompanyCol>
            )
        } else {
            return (
                <CompanyCol xs={12} imageurl={imageUrl} height={"400px"} basisPremiumConfig={props.tileData.basisPremiumConfig} isfiller={false}>
                    <DetailNavLink id={props.tileData.id} type="organization" name={props.tileData.name} source={props.src} searchKrit={props.searchKrit}>
                        <TileHeader
                            tileData={props.tileData} onAction={props.onAction} />
                        <TileBody content={props.tileData} strings={props.strings} />
                    </DetailNavLink>
                </CompanyCol>
            )
        }
    } else if (tileSize === TileSize.THREE_QUARTERS) {
        return (
            <CompanyCol xs={18} imageurl={imageUrl} height={"400px"} basisPremiumConfig={props.tileData.basisPremiumConfig} isfiller={false}>
                <DetailNavLink id={props.tileData.id} type="organization" name={props.tileData.name} source={props.src} searchKrit={props.searchKrit}>
                    <TileHeader
                        tileData={props.tileData} onAction={props.onAction} />
                    <TileBody content={props.tileData} strings={props.strings} />
                </DetailNavLink>
            </CompanyCol>
        )
    }
    else if (tileSize === TileSize.FULL) {
        return (
            <CompanyCol xs={24} imageurl={imageUrl} height={"400px"} basisPremiumConfig={props.tileData.basisPremiumConfig} isfiller={false}>
                <DetailNavLink id={props.tileData.id} type="organization" name={props.tileData.name} source={props.src} searchKrit={props.searchKrit}>
                    <TileHeader
                        tileData={props.tileData} onAction={props.onAction} />
                    <TileBody content={props.tileData} strings={props.strings} />
                </DetailNavLink>
            </CompanyCol>
        )
    } else {
        return null
    }
}
/* #endregion */




function getNumColumnsForTileSize(tileSize: TileSize) {
    if (tileSize === TileSize.FULL)
        return 24;
    if (tileSize === TileSize.THREE_QUARTERS)
        return 18;
    if (tileSize === TileSize.HALF)
        return 12;
    if (tileSize === TileSize.QUARTER)
        return 6;
    return 3;
}

function getTileSizeForNumColumns(size: number) {
    if (size === 24)
        return TileSize.FULL;
    if (size === 18)
        return TileSize.THREE_QUARTERS;
    if (size === 12)
        return TileSize.HALF;
    if (size === 6)
        return TileSize.QUARTER;
    return TileSize.SMALL;
}

function getNeededFillerSizes(size: number): Array<number> {
    if (size === 21)
        return [12, 6, 3];
    if (size === 18)
        return [12, 6];
    if (size === 15)
        return [12, 3];
    if (size === 12)
        return [12];
    if (size === 9)
        return [6, 3];
    if (size === 6)
        return [6];
    return [3];
}

function getFillerData(tileSize: TileSize, color: string, fillersData: any): TileData | null {
    let obj;
    let randTile;
    if (tileSize === TileSize.HALF) {
        randTile = Math.floor(Math.random() * fillersData.standard.length + 0);
        obj = fillersData.standard[randTile];
    }
    else if (tileSize === TileSize.QUARTER) {
        randTile = Math.floor(Math.random() * fillersData.basic.length + 0);
        obj = fillersData.basic[randTile];
    }
    else if (tileSize === TileSize.SMALL) {
        randTile = Math.floor(Math.random() * fillersData.none.length + 0);
        obj = fillersData.none[randTile];
    }
    const rand = Math.floor(Math.random() * 100);
    const imageUrl = rand % 2 === 0 ? "" : "/branding/placeholder-shapes.png";

    if (obj) {
        return {
            id: "filler" + Math.random(),
            name: obj?.title,
            teaser: obj?.subtitle ? obj?.subtitle : "",
            contacts: [],
            isFiller: false,
            filler: null,
            basisPremiumConfig: getBasisPremiumConfigForValues(tileSize, imageUrl.length > 0),
            fillerLink: obj.to,
            fillerLinkText: obj?.link,
            fillerColor: color,
            backgroundImageURL: imageUrl
        }
    }
    return null;
}

function makeGrid(exhibitors: Exhibitor[], sectionType: SectionType, itemCount: number, fillersData: any, ignoreBasisPremium: boolean): TileData[] {
    let availableColumns = 24;
    let currentGrid: TileData[] = []


    for (let index = 0; index < exhibitors.length; index++) {
        const item = exhibitors[index]

        const exhibitor = item as Exhibitor

        let basisPremiumConfig = getBasisPremiumConfig(ignoreBasisPremium ? BasisPremiumType.NONE : exhibitor.basisPremium)

        if (sectionType === SectionType.TOP && branding.exhibitorsPageContent.specialTileSizeForSponsors) {
            basisPremiumConfig = getBasisPremiumConfig(BasisPremiumType.SPONSOR)
        }


        const neededColumnsForTileSize = getNumColumnsForTileSize(basisPremiumConfig.tileSize);
        if (availableColumns === 0)
            availableColumns = 24;

        if (neededColumnsForTileSize > availableColumns) {
            const fillers = getNeededFillerSizes(availableColumns);
            for (let j = 0; j < fillers.length; j++) {

                const tileSize = getTileSizeForNumColumns(fillers[j]);

                const content = getFillerData(tileSize, "#FFF", fillersData);
                const filler = getFillerData(tileSize, branding.exhibitorsPageContent.tilePlaceholderBgColor ?? "#000", fillersData);
                let withBackgroundImage = !!(content?.backgroundImageURL && content.backgroundImageURL?.length > 0)
                currentGrid.push({ ...content!, basisPremiumConfig: getBasisPremiumConfigForValues(tileSize, withBackgroundImage), isFiller: true, filler: filler });
            }
            index--;
            availableColumns = 24;
        } else {

            availableColumns -= neededColumnsForTileSize;
            if (neededColumnsForTileSize === 3) {
                let nextExhibitor: Exhibitor | undefined = undefined

                for (let nextExhibitorIndex = index + 1; nextExhibitorIndex < exhibitors.length; nextExhibitorIndex++) {
                    if (exhibitors[nextExhibitorIndex].type === "organization") {
                        nextExhibitor = exhibitors[nextExhibitorIndex] as Exhibitor
                        break
                    }
                }
                const nextBasisPremiumConfig = nextExhibitor ? getBasisPremiumConfig(ignoreBasisPremium ? BasisPremiumType.NONE : nextExhibitor.basisPremium) : undefined
                if (nextExhibitor && nextBasisPremiumConfig && nextBasisPremiumConfig.tileSize === TileSize.SMALL) {
                    currentGrid.push({ ...exhibitor, basisPremiumConfig: basisPremiumConfig, filler: { ...nextExhibitor, basisPremiumConfig: nextBasisPremiumConfig, isFiller: false, filler: null } })
                    index++
                }
                else {
                    const filler = getFillerData(basisPremiumConfig.tileSize, branding.exhibitorsPageContent.tilePlaceholderBgColor ?? "#000", fillersData);
                    if (filler) filler!.isFiller = true
                    currentGrid.push({ ...exhibitor, basisPremiumConfig: basisPremiumConfig, filler: filler })
                }
            } else {
                currentGrid.push({ ...exhibitor, basisPremiumConfig: basisPremiumConfig, basisPremiumType: exhibitor.basisPremium, filler: null })
            }
        }
    }
    return currentGrid
}

/* #region  Main content */
interface CompaniesTilesLayoutProps {
    type: DetailNavLinkType
    sections: Sections
    onAction: (action: "EXPO" | "MEETING" | "INTEREST" | "RECOMMEND", organization: SimpleOrganization) => void
    isSponsor: boolean
    isMediaPartner: boolean
    isStartup: boolean
    searchKrit?: string
}

export const CompaniesTilesLayout: React.FC<CompaniesTilesLayoutProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const fillersData = strings.exhibitorsPageContent.fillersData;

    //check if is sponsors page and if basisPremium should be ignored
    const isSponsorPage = useRouteMatch(sponsorsPageRoute)
    const isMediaPartnerPage = useRouteMatch(mediaPartnerPageRoute)
    const isExhibitorStartupPage = useRouteMatch(exhibitorStartupPageRoute)

    const ignoreBasisPremium = isSponsorPage && isMediaPartnerPage && isExhibitorStartupPage && branding.sponsorsPageContent.ignoreBasisPremium

    return (
        <>
            {
                sectionOrder.map((sectionType, outerKey) => {
                    const section = props.sections[sectionType]
                    if (!section || section.count === 0)
                        return null
                    const titleSponsors = `${strings.receptionPage.sponsorsTitle}(${section.count.toString()})`
                    const titleTemplate = props.isSponsor ? titleSponsors :
                        section.type === SectionType.TOP ? strings.exhibitorsPageContent.sectionHeaderTop : strings.exhibitorsPageContent.sectionHeaderAllExhibitors
                    const src: UserOrganizationVisitSource = props.isSponsor ? 'SPONSORS' : section.type === SectionType.TOP ? 'FLOORSPONSOR' : 'FLOOR'
                    const title = titleTemplate.split('{$count}').join(section.count.toString())
                    const grid = makeGrid(section.results as Exhibitor[], section.type, section.count, fillersData, ignoreBasisPremium!)
                    return <div key={outerKey} style={{ marginBottom: "10px" }}>
                        <SectionHeaderTitle>{title}</SectionHeaderTitle>
                        <CompanyGrid>
                            <CompanyRow>
                                {grid.map((tileData) => {
                                    return <OrganizationTile
                                        key={tileData.id}
                                        src={src}
                                        searchKrit={props.searchKrit}
                                        tileData={tileData}
                                        onAction={props.onAction}
                                        strings={strings} />
                                })}
                            </CompanyRow>
                        </CompanyGrid >
                    </div>
                })
            }
        </>
    )
}

function getBasisPremiumConfigForValues(tileSize: TileSize, tileBackgroundImageVisible: boolean) {
    const basisPremiumConfig = getDefaultBasisPremiumConfig()
    basisPremiumConfig.tileSize = tileSize
    basisPremiumConfig.tileBackgroundImageVisible = tileBackgroundImageVisible

    return basisPremiumConfig
}

/* #endregion */
