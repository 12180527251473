// eslint-disable-next-line
const environment = 'integration'

export function getEnvironment(): string {
    const hostname = window.location.hostname

    if (hostname === "localhost" || hostname === "127.0.0.1" || hostname.startsWith("192.168.10.")) {
        return "dev"
    } else {
        return environment
    }
}

export function getEnvironmentShort(): "dev" | "int" | "stage" | "live" {
    switch (getEnvironment()) {
        case "dev":
            return "dev"
        case "integration":
            return "int"
        case "staging":
            return "stage"
        default:
            return "live"
    }
}

export function getProtocolAndHost() {
    const environment = getEnvironment();
    return environment === "dev" ? "http://localhost:8080" : `https://${environment}.messebackend.aws.corussoft.de`;
}

export function getAppUrl() {
    return window.location.protocol + '//' + window.location.hostname + ((window.location.port !== "") ? (':' + window.location.port) : "")
}