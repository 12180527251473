import React from "react"
import { isAndroid, isIOS, isSafari } from "react-device-detect"
import styled from "styled-components"
import branding from "./branding/branding"
import { useLanguageState } from "./globalStates/LanguageState"
import { ContentScrollContainer } from "./ui/ScrollContainer"

const MobileWebsiteCheckSiteRoot = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    overflow-y: hidden;
    height: 100vh;
    font-family: ${branding.font1};
`

const MobileWebsiteCheckStyleOverlay = styled.div`
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgb(229, 229, 229, 0.9);
`

const MobileWebsiteCheckStyleContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: contain;
    width: 100%;
    z-index: 2;
`

const InfoRoot = styled.div`
    position: relative;
    height: 100vh;
    background: #fff;
`

const InfoContainer = styled.div` 
    margin: 0;
    position: absolute;
    top: 65%;
    left: 47.5%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;


    @media (min-width: 700px) {
        left: 48%;
    }

    @media (max-height: 670px) {
        top: 60%;
    }
`

const MessageContainer = styled.div` 
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 700px) {
        width: 600px;
    }

    @media (max-height: 670px) {
        width: 320px;
    }

    @media (max-height: 600px) {
        width: 300px;
    }
`

const ScrollContainerRoot = styled.div`
  display: flex;
  flex-direction: column;

  &.withHeight {
    height: calc(100vh - 40px);
  }
`

const FairLogo = styled.div`
   width: 6rem;
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 50px;
`

const Subtitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
`

const Text = styled.div`
    font-size: 16px;
    line-height: 18.75px;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-left: 18px;
    margin-right: 18px;
    font-weight: 400;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
`

const ButtonsContainer = styled.div`
    margin-top: 40px;
`

const MainButton = styled.button`
    flex: 0 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 7px;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color:  ${branding.loginRegistrationSite.mobileLoginButtonColor};;
    color: #fff;
    transition: 0.5s;
    cursor: pointer;
    width: 100%;
    outline: none;

    &:focus { outline: none; }

    &:hover {
        opacity: 0.7;
    }
`

const SimpleButton = styled.div`
    color: ${branding.loginRegistrationSite.mobileLoginButtonColor};
    cursor: pointer;
    font-weight: 700;
    margin-left: auto;
    margin-right: auto;

    &:hover {
        text-decoration: underline;
    }
`

const ImgContainer = styled.div`
    cursor: pointer;
    width: 100%;
    height: 30%;
    bottom: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Img = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`


const MobileWebsiteCheck: React.FunctionComponent = () => {
    const strings = useLanguageState().getStrings()

    const onAppDownloadClick = () => {

        if (isAndroid) {
            window.open(`${strings.loginRegistrationSite.androidAppUrl}`, "_blank")
        }
        else if (isIOS) {
            window.open(`${strings.loginRegistrationSite.iOSAppUrl}`, "_blank")
        }
        else {
            window.open(`${strings.loginRegistrationSite.androidAppUrl}`, "_blank")
        }
    }

    const onContinueWithMobileClick = () => {
        localStorage.removeItem("virtualGuide-mobileWebsiteEnabled");
        localStorage.setItem("virtualGuide-mobileWebsiteEnabled", "true");
        window.location.reload();
    }

    return <MobileWebsiteCheckSiteRoot>
        <MobileWebsiteCheckStyleContainer>
            <MobileWebsiteCheckStyleOverlay>
                <InfoRoot>
                    <ScrollContainerRoot>
                        <ContentScrollContainer>
                            <InfoContainer style={{ left: (isIOS || isSafari) ? "50%" : "47.5%", top: (isIOS || isSafari) ? "71.5%" : "65%" }}>
                                <MessageContainer>
                                    <FairLogo>
                                        <img src="./branding/logo_dark.png" alt={strings.configuration.ogTitle} />
                                    </FairLogo>
                                    <Subtitle>
                                        {strings.loginRegistrationSite.moreOptionsForMobileTitle}
                                    </Subtitle>
                                    <Text>
                                        {strings.loginRegistrationSite.moreOptionsForMobileDescription}
                                    </Text>
                                    <ButtonsContainer>
                                        <MainButton onClick={onAppDownloadClick}>
                                            {strings.loginRegistrationSite.downloadMobileAppButtonText}
                                        </MainButton>
                                        <SimpleButton>
                                            <p onClick={onContinueWithMobileClick}>
                                                {strings.loginRegistrationSite.continueWithMobileButtonText}
                                            </p>
                                        </SimpleButton>
                                    </ButtonsContainer>
                                </MessageContainer>
                                <ImgContainer>
                                    <Img src="./branding/mobile_check_background.png" alt={strings.configuration.ogTitle} />
                                </ImgContainer>
                            </InfoContainer>
                        </ContentScrollContainer>
                    </ScrollContainerRoot>

                </InfoRoot>

            </MobileWebsiteCheckStyleOverlay>
        </MobileWebsiteCheckStyleContainer>
    </MobileWebsiteCheckSiteRoot>
}

export default MobileWebsiteCheck;