import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { IconCloseBanner } from "../../ui/Icons"
import branding from "../../branding/branding"
import { DetailNavLink } from "../detailPages/DetailNavLink"
import { BannerBrandingItem, BannerPool } from "./TopBannerBranding"

const TopBannerRoot = styled.div<{ hide: boolean }>`
    position: relative;
    border-bottom: ${props => props.hide ? "none" : "1px solid " + branding.mainInfoColor};
    padding-top: ${props => props.hide ? '0' : '12.5%'};
    transition: padding .3s;
`
const TopBannerContent = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0; 
    left: 0;
    & img { 
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`
const TopBannerCloseBtn = styled.div<{ hide: boolean }>`
    position: absolute;
    display: ${props => props.hide ? 'none' : 'flex'}; 
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 1px;
    top: 20px;
    right: 20px;
    height: 27px;
    border-radius: 25px;
    z-index: 2;
    opacity: ${props => props.hide ? 0 : 1};
    transition: opacity .3s;
    cursor: pointer;
    & span {
        display: inline-block;
        text-transform: uppercase;
        font-family: ${branding.font1};
        margin: 0 .6rem 0 .6rem;
        font-size: 12px;
    }
    & .close-icon-wrapper {
        width: 25px;
        height: 25px; 
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        border-radius: 50%;

        & svg * {
            stroke: #fff !important;
        }
    }
`

interface TopBannerProps {
    type: string,
    onHideBanner: Function,
    hideOnScroll?: boolean,
    setRef: Function
}

const TopBanner: React.FunctionComponent<TopBannerProps> = (props) => {
    const [currentBanner, setCurrentBanner] = useState<BannerBrandingItem>()
    const [showBanner, setShowBanner] = useState<boolean>(true)
    const bannerPool = branding.topBanner.bannerPools.find((pool: BannerPool) => pool.title === props.type) ||
        branding.topBanner.bannerPools.find((pool: BannerPool) => pool.title === "default")

    const topBarRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        let randomBannerIndex = Math.floor(Math.random() * bannerPool?.items.length!);
        setCurrentBanner(bannerPool?.items[randomBannerIndex])
        // eslint-disable-next-line
    }, [window.location.pathname])

    useEffect(() => {
        if (topBarRef && topBarRef.current)
            props.setRef(topBarRef)
        // eslint-disable-next-line
    }, [topBarRef])

    const TopBannerContentLink = () => {
        // external Link
        if (currentBanner?.externalLink) {
            return (
                <a href={currentBanner.externalLink} target={currentBanner.externalLink === "/" ? "" : "blank"}>
                    <TopBannerContent>
                        <img src={'/branding/advertisingBanners/' + currentBanner?.image || ''} alt="" />
                    </TopBannerContent>
                </a>
            )
        }
        // internal Links, except to Exhibitors (those are Sponsor-Links, that need to be tracked)
        if (currentBanner?.internalLink) {
            if (currentBanner.internalLink.indexOf('/company/') < 0) {
                return (
                    <a href={currentBanner.internalLink} target={currentBanner.internalLink === "/" ? "" : "_self"}>
                        <TopBannerContent>
                            <img src={'/branding/advertisingBanners/' + currentBanner?.image || ''} alt="" />
                        </TopBannerContent>
                    </a>
                )
            }
        }
        // Sponsor-Links to Exhibitors
        return (
            <DetailNavLink id={currentBanner?.id || ''} type="organization" name={currentBanner?.name || ''} width={"100%"} source="BANNER">
                <TopBannerContent>
                    <img src={'/branding/advertisingBanners/' + currentBanner?.image || ''} alt="" />
                </TopBannerContent>
            </DetailNavLink>
        )
    }

    return (
        <TopBannerRoot ref={topBarRef} hide={!showBanner || (showBanner && props.hideOnScroll !== undefined && props.hideOnScroll)}>
            <TopBannerCloseBtn hide={!showBanner || (props.hideOnScroll !== undefined && props.hideOnScroll)} onClick={() => { setShowBanner(false); props.onHideBanner() }}>
                <div className={'close-icon-wrapper'}>{IconCloseBanner({ fill: branding.sideIconBar.sideIconColorDark })}</div>
            </TopBannerCloseBtn>
            <TopBannerContentLink />
        </TopBannerRoot>
    )
}

export default TopBanner
